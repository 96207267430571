.head-position {
  margin-top: -40px;
}
.body-transparent {
  background: #F1F1F7 !important;
  box-shadow: none !important;
}
.search-partner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;
  position: relative;
}
.form-search {
  width: 35%;
  background: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 6px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  // justify-content: center;
  position: relative;
  input {
    border: none;
    padding: 12px 10px;
    width: 100%;
    font-size: 14px;
    color: #828282;
    &::placeholder {
      color: #828282;
    }
    &:focus {
      outline: none;
    }
    &:active {
      outline: none;
    }
  }
  .btn-success {
    background: #17A84B;
    border-radius: 6px;
    padding: 5px 10px;
  }
}
.form-input-projects {
  padding: 0 !important;
  background: #FFFFFF !important;
  border-radius: 6px !important;
  border: 1px solid #E3E3E3 !important;
  input {
    background: #FFFFFF !important;
    border-radius: 6px !important;
    padding: 6px 10px !important;
  }
}
.hide-search {
  background-color: transparent !important;
  border: none !important;
  z-index: -999 !important;
}
.floating-select{ 
  position: absolute;
  height: 150px;
  overflow-y: none;
  overflow-x: scroll;
  margin-top: 60px;
  top: 0;
  left: 0;
  right: 0;
  background: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 6px;
  z-index: 10;
  .item-data{
    padding: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.63);
    &:hover {
      color: #fff;
      background-color: #17A84B;
    }
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
}
.height-content{
  min-height: 80vh;
  max-height: 100%;
}
.img-partner-user {
  height: 40px !important;
  width: 40px !important;
  border-radius: 10px !important;
}
.p-left {
  padding-left: 15px;
}
.p-left-2 {
  padding-left: 25px;
}
.content-tbody {
  td {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #000000;
    div {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #000000;
    }
    span {
      text-transform: capitalize;
    }
    .name-user {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
    }
    .frame-email {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-left: -5px;
    }
    .email-user {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-decoration-line: underline;
      color: rgba(38, 37, 37, 0.8);
    }
  }
}
.content-action {
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
    &:nth-child(1){
      margin-left: 0px;
    }
  }
}

// detail partner

.partner-side-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  // padding: 0 5px;
}
.left-partner {
  display: flex;
  align-items: center;
}
.partner-details {
  display: flex;
  margin-left: 10px;
  .frame-img-partners {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    img {
      border-radius: 10px;
      height: 100%;
      width: 100%;
    }
  }
  .name-user {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }
  .frame-email {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-left: -5px;
    .email-user {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      text-decoration-line: underline;
      color: rgba(38, 37, 37, 0.8);
    }
  }
}
.right-partner {
  display: flex;
}
.form-partner {
  display: flex;
  align-items: center;
  .frame-button {
    margin-left: 10px;
    margin-right: 5px;
    height: 100%;
    .btn-success {
      background: #17A84B;
      border-radius: 6px;
      padding: 5px 10px;
    }
  }
}
.inline-form-partner {
  label {
    display: flex;
    align-items: center;
    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.63);
      margin-right: 5px;
    }
    .frame-form-partner {
      background: #FFFFFF;
      border: 1px solid #E3E3E3;
      border-radius: 6px;
      padding: 8px 10px;
      min-width: 160px;
      max-width: 100%;
      select {
        border: none;
        background-color: transparent;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.63);
        text-align: left;
        &:active {
          border: none;
          background-color: transparent;
          outline: none;
        }
        &:disabled{
          background: #E8E8E8;
          border: 1px solid #E3E3E3;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: rgba(0, 0, 0, 0.63);
        }
      }
    }
  }
}
.disabled-item {
  background: #E8E8E8 !important;
}
// modal detail partner
.modal-featured-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.modal-featured-content {
  padding: 20px 10px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.content-modal-featured {
  width: 90%;
  h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #000000;
  }
  .button-featured-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .btn-cancel{
    background: #5867DD;
    border: 1px solid #5867DD;
    border-radius: 6px;
    width: 134px;
    height: 45px;
    margin-right: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
  }
  .btn-okay{
    background: #FD397A;
    border: 1px solid #FD397A;
    border-radius: 6px;
    width: 134px;
    height: 45px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
  }
}
.modal-costume-width-partner {
  width: 45%;
  max-width: none !important;
}
.modal-featured-table{
  .modal-featured-header {
    padding: 10px 15px;
    padding-bottom: 20px;
  }
  .modal-featured-content {
    padding: 5px 45px;
  }
}
.green-series{ 
  margin-left: 3px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6CBB07 !important;
}