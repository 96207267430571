.caretLeft {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: #000;
  -webkit-mask: url("/media/icons/caret-left.svg") no-repeat center;
  mask: url("/media/icons/caret-left.svg") no-repeat center;
}
.closeIcon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: #000;
  -webkit-mask: url("/media/icons/closeIcon.svg") no-repeat center;
  mask: url("/media/icons/closeIcon.svg") no-repeat center;
}
.closeIcon-white {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: #000;
  -webkit-mask: url("/media/icons/closeIcon-white.svg") no-repeat center;
  mask: url("/media/icons/closeIcon-white.svg") no-repeat center;
}
.searchUser {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: #000;
  -webkit-mask: url("/media/icons/search-user.svg") no-repeat center;
  mask: url("/media/icons/search-user.svg") no-repeat center;
}
.searchData {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: #000;
  -webkit-mask: url("/media/icons/search-icon.svg") no-repeat center;
  mask: url("/media/icons/search-icon.svg") no-repeat center;
}

.iconEmptyCheck {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: #000;
  -webkit-mask: url("/media/icons/emptyCheck.svg") no-repeat center;
  mask: url("/media/icons/emptyCheck.svg") no-repeat center;
}
.iconCheck {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: #000;
  -webkit-mask: url("/media/icons/checked.svg") no-repeat center;
  mask: url("/media/icons/checked.svg") no-repeat center;
}
.showIcon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: #000;
  -webkit-mask: url("/media/icons/show.svg") no-repeat center;
  mask: url("/media/icons/show.svg") no-repeat center;
}

.removeIcon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: #000;
  -webkit-mask: url("/media/icons/removeIcon.svg") no-repeat center;
  mask: url("/media/icons/removeIcon.svg") no-repeat center;
}
.removeMedia {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: #000;
  -webkit-mask: url("/media/icons/remove-media.svg") no-repeat center;
  mask: url("/media/icons/remove-media.svg") no-repeat center;
}

.copy-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: #000;
  -webkit-mask: url("/media/icons/copy.svg") no-repeat center;
  mask: url("/media/icons/copy.svg") no-repeat center;
}