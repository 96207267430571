.frame-table-nft {
  width: 100%;
  padding: 20px 0 ;
}
.frame-header-table {
  .searching-nft {
    display: flex;
    justify-items: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 6px;
    width: 100%;
    padding: 5px 10px;
    input {
      width: 100%;
      padding: 5px 10px;
      border: none;
      outline: none;
      &:active {
        border: none;
        outline: none;
      }
      &:focus {
        border: none;
        outline: none;
      }
      &::placeholder {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.43);
      }
    }
  }
  .search-info {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .infoIcon {
      background-color: #17A84B;
    }
    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: #17A84B;
    }
  }
  .search-danger {
    .infoIcon {
        background-color: rgba(234, 13, 13, 0.4) !important;
    }
    span {
      color: rgba(234, 13, 13, 0.4) !important;
    }
  }
}
.size-table {
  height: 350px;
  overflow-y: none;
  overflow-x: scroll;
  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
}
.detail-table {
  .name-user {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }

  .frame-email {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-left: -5px;
  }

  .email-user {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-decoration-line: underline;
    color: rgba(38, 37, 37, 0.8);
  }
}
.price-table-nft {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
// .frame-footer-table {}
.content-footer-table {
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn-success {
    background: #17A84B;
    border-radius: 6px;
  }
}
.checkboxPartner {
  display: none;
}
.inputTable {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  label {
    padding-top: 10px;
    margin: 0;
  }
}

.gray-check-Icon {
  background: rgba(15, 18, 39, 0.48) !important;
}
.green-check-Icon {
  background: #17A84B !important;
}
.loading-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #931818 !important;
  }
}