//
// Progress
// 

.progress {	        
    background-color: kt-base-color(grey, 2);

    .progress-bar {
        transition: all 0.5s ease;
    }    

    &.progress-sm {
        height: 6px;  

        .progress-bar {
        	border-radius: 3px;
        }        	
    }

    &.progress-lg {
        height: 20px;

       	.progress-bar {        		
        	border-radius: 4px;
        }
    }
}