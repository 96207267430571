@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "./artwork.scss"; // Standard version
@import "./collectibles.scss"; // Standard version
@import "./featured-partner.scss";
@import "./icons.scss";
.sort-button{
    border: none;
    background: transparent;
}

.center{
    text-align: center;
}

.all-center{
    vertical-align: middle!important;
    text-align: center;
}

.row-search{
    padding-bottom: 2em;
}

@media (max-width: 1024px) {
    .row-search{
        margin-top: 5em!important;
    }
}

.head{
    margin-top: -20px;
}

@media screen and (max-width: 922px){
    .head{
        margin-top: 20px;
    }
}

.margin-botHalf{
    margin-bottom: .5em;
}

.left-modal{
    text-align: left;
    display: grid;
}

.no-data{
    position:absolute;
    top:50%;left:50%;
    transform:translate(-50%,-50%);
    color:#b5b2b2;font-size:16px
}

.captcha-position{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.required{
    color: red;
    font-size: 12px;
    padding: 5px;
}

.input-otp {
    border: none;
    width: 15.5ch;
    margin-bottom: 5px;
    background: 
        repeating-linear-gradient(90deg, 
            dimgrey 0, 
            dimgrey 1.4ch, 
            transparent 0, 
            transparent 2.2ch) 
            0 100%/80% 2px no-repeat;
    color: dimgrey;
    font: 5ch consolas, monospace;
    letter-spacing: 1.25ch;

    &:focus {
        outline: none;
        color: #000;
        background: 
            repeating-linear-gradient(90deg, 
                #1AA64A 0, 
                #1AA64A 1.4ch, 
                transparent 0, 
                transparent 2.2ch) 
            0 100%/80% 2px no-repeat;
    }
}

// .status {
//     padding: 0 5px;
//     display: inline-block;
//     text-transform: uppercase;
//     border-radius: 4px;
//     color: #000;
//     font-weight: bold;
   
//     &.approve {
//       background-color: #00cc00;
//       // background-color: #36c6d3;
//     }
   
//     &.one {
//       background-color: #ff8000;
//       // background-color: #337ab7;
//     }
   
//     &.two {
//       background-color: #f1c40f;
//     }
   
//     &.three {
//       background-color: #ed6b75;
//     }
// }

.link-action{
    cursor: pointer;
    color: #000;
    padding: 2px;
    &:hover{
        color: darkcyan;
    }
}

.font-delete{
    justify-content: center;
    display: flex;
    font-size: 15px;
    font-weight: 400;
}
.tableheh{
    border-bottom: 2px solid #ebedf2 !important;
    border-top: 1px solid #EFF0F1 !important;
}
.kt-portlet-top{
    margin-top: -15px;
}
.pddmdForce{
    padding: 20px 20px !important;
}
.pddlessForce{
    padding: 0 !important;
}
.paddupgrade{
    padding: 10px 10px;
    margin-left: 10px;
}

.label-dot {
    display: inline-block;
    font-size: 0!important;
    vertical-align: middle;
    text-align: center;
    line-height: 6px;
    min-height: 6px;
    min-width: 6px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
}
.success{
    background-color: #28a745;
}
.danger{
    background-color: #dc3545;
}

.totalAmmount{
    font-size: 12px;
    font-weight: 500;
    color: #181c32;
}

.pad-10-20{
    padding: 10px 8px !important;
}

.font-8rem{
    font-size: 0.8rem!important;
}

.btn-register-home{
    color: #fff!important;
    width: auto;
    height: 38px;
    font-size: .8rem;
    left: 0px;
    top: 230px;
    background: #1AA64A;
    box-shadow: 0px 2px 4px rgba(26, 166, 74, 0.1);
    border-radius: 4px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 0px;
    margin-left: 5px;
    @media screen and (max-width: 991px) {
        margin-top: 10px;
    }
    @media screen and (max-width: 500px) {
        // margin-left: 10px;
    }
    &:hover{
        color: #EAECEF;
    }
}

.btn-event-home{
    margin-left: 0;
    width: auto;
    height: 38px;
    font-size: .8rem;
    background: #F7F7F7;
    border: 1px solid #EAECEF;
    border-radius: 4px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: 1px;
    color: #535353;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 0px;
    margin-left: 5px;
    @media screen and (max-width: 991px) {
        margin-top: 10px;
    }
}
.btn-event-home-white{
    margin-left: 0;
    width: auto;
    height: 38px;
    font-size: .8rem;
    background: #FFFFFF;
    border: 1px solid #EAECEF;
    border-radius: 4px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: 1px;
    color: #535353;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 0px;
    margin-left: 5px;
    @media screen and (max-width: 991px) {
        margin-top: 10px;
    }
}
.btn-event-home-border-green{
    margin-left: 0;
    width: auto;
    height: 38px;
    font-size: .8rem;
    background: #FFFFFF;
    border: 1px solid #1AA64A;
    border-radius: 4px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: 1px;
    color: #535353;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 0px;
    margin-left: 5px;
    @media screen and (max-width: 991px) {
        margin-top: 10px;
    }
}
.img-th{
    height: 48px;
    width: 48px;
    background-size: cover;
    margin-left: 5px;
    margin-right: 5px;
}
.img-bn{
    height: 100%;
    width: 100px;
    background-size: cover;
    margin-left: 5px;
    margin-right: 5px;
}

.btn-action{
    cursor: pointer;
    padding: 2px 4px;
    border-radius: 4px;
}
.dropzone{
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: start;
  border: 0px transparent;
  border: 1px solid #E2E5EB;
  padding: 15px 15px;
  flex-wrap: wrap;
  -webkit-box-shadow: -1px 3px 7px 0px rgba(197, 193, 193, 0.44);
  -moz-box-shadow: -1px 3px 7px 0px rgba(197, 193, 193, 0.44);
  box-shadow: -1px 3px 7px 0px rgba(197, 193, 193, 0.44);
}
.setTitleDropzone{
  color: gray;
  font-style: bold;
  font-weight: 500;
  justify-content: start;
  align-items: center;
  text-align: center;
  span{
      cursor: pointer;
      color: #AB92EF;
  }
}
.setSubTitleDropzone{
  margin-bottom: 0px;
  margin-top: -12px;
  font-weight: 500;
  justify-content: start;
  align-items: center;
  text-align: start;
  color: gray;
}
.img-preview{
  height: 250px;
  width: 100%;
  background-size: cover;
  background-position: center;
  object-fit: contain;
}

.width-date{
    width: 100%!important;
}

.desc-length-position{
    text-align: right;
}

.react-datepicker-wrapper{
    display: block;
}
.radius-left{
    border-radius: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
} 
.radius-remove{
    border-radius: 0px;
} 
.radius-right{
    border-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.img-loading{
    object-fit: contain;
    height: 100px;
    width: 100%;
}

.container-button{
    // margin-left: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .grouping-button{
        width: 32% !important;
        display: flex;
        margin-right: 10px;
        margin-top: 20px;
        .btn-in-group{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            padding: 10px 10px;
        }
        .btc-s{
            background: #2DA602;
            border-radius: 8px 0px 0px 8px;
            color: #FFFFFF;
            text-transform: uppercase;
        }
        .btn-outline{
            color: #2DA602;
            border: 1px solid #2DA602;
            box-sizing: border-box;
            border-radius: 0px 8px 8px 0px;
        }
        .btn-outline:hover{
            background: #2DA602;
            color: #FFFFFF;
        }
        .btn-without-border-lr{
            border-radius: 0px 0px 0px 0px;
            border-left: transparent 0px;
            border-right: transparent 0px;
        }
    }

    .grouping-button-new{
        display: flex;
        .btn-in-group{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            padding: 10px 10px;
        }
        .btc-s{
            background: #2DA602;
            border-radius: 8px 0px 0px 8px;
            color: #FFFFFF;
            text-transform: uppercase;
        }
        .btn-outline{
            color: #2DA602;
            border: 1px solid #2DA602;
            box-sizing: border-box;
            border-radius: 0px 8px 8px 0px;
        }
        .btn-outline:hover{
            background: #2DA602;
            color: #FFFFFF;
        }
        .btn-without-border-lr{
            border-radius: 8px 0px 0px 8px;
            border-right: transparent 0px;
        }
    }

    .grouping-button-small{
        width: auto !important;
        display: flex;
        margin-right: 5px;
        margin-top: 20px;
        .btn-in-group{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            padding: 10px 10px;
        }
        .btc-s{
            background: #2DA602;
            border-radius: 8px 0px 0px 8px;
            color: #FFFFFF;
            text-transform: uppercase;
        }
        // .btc-s:hover{
        //     color: #2DA602;
        //     border: 1px solid #2DA602;
        //     background: #fff;
        // }
        .btn-outline{
            color: #2DA602;
            border: 1px solid #2DA602;
            box-sizing: border-box;
            border-radius: 0px 8px 8px 0px;
        }
        .btn-outline:hover{
            background: #2DA602;
            color: #FFFFFF;
        }
        .btn-without-border-lr{
            border-radius: 0px 0px 0px 0px;
            border-left: transparent 0px;
            border-right: transparent 0px;
        }
    }
}
.pagination-data{
    .pagination{
        .page-item{
            margin-left: 5px;
            margin-right: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            .page-link{
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 19px;
                color: #838383;
                background-color: transparent;
                border-color: transparent;
            }
        }
        .active{
            margin-left: 5px;
            margin-right: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            .page-link{
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 27px;
                color: #000000;
                background-color: transparent;
                border-color: transparent;
            }
        }
    }
}

.peruri {
    font-weight: 600;
    color: #005398;
}
.asliri {
    font-weight: 600;
    color: #b80f0a;
}

.input-date {
    background: #FFFFFF;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 0.65rem 1rem;
    width: 100%;
    height: calc(1.5em + 1.3rem + 7px);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
}

.mri-input{
    margin-right: 5px !important;
}

.remove-frame-button{
    padding: 0 !important;
    margin: 0;
}

.dasboard-content-title-card{
    text-align: right;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    margin-bottom: 5px;
}

.dasboard-content-value-card{
    text-align: right;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 72px;
    color: #535353;
}

.title-menu-config{
    margin: 0;
    padding: 0 1rem 0 0;
    font-size: 1.2rem;
    font-weight: 500;
    color: #434349;
    display: flex;
    align-items: center;
}

.title-popup-style{
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}
.desc-popup-style{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #0C0C0C;
    p{
        img{
            width: 500px!important;
        }
    }
}

.role-user{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 19px;
    color: #FAB203;
}
.name-user{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}
.email-user{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    color: #A9A9A9;
}
.img-list-data{
    width: 73px;
    height: 73px;
    margin-right: 10px;
    border-radius: 10px;
}
.img-detail {
    width: 196px;
    height: 196px;
    border-radius: 10px;
}
.from-to-user{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}
.ammount-tko{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #17A84B;
}

.modal-costume-width{
    width: 70%;
    max-width: none!important;
}

.detail-trx-title{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: rgba(38, 37, 37, 0.8);
    margin-bottom: 13px;
}
.detail-trx-address{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: rgba(38, 37, 37, 0.8);
    margin-bottom: 18px;
}

.desc-dnd{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    width: 578px;
    color: #000000;
}

.pointers{
    cursor: pointer;
}