.detail{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 36px 44px 52px;

    img{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-bottom: 12px;
    }

    .detail-artwork-name{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
    }
    .detail-artwork-join{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.02em;
        color: rgba(38, 37, 37, 0.4);
        margin-bottom: 13px;
    }
    .detail-artwork-address{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: rgba(38, 37, 37, 0.8);
        margin-bottom: 21px;
    }
    .detail-artwork-description{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        text-align: center;
        color: #000000;
        width: 616px;
        margin-bottom: 60px;
    }

    .detail-artwork-status{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        color: #000000;
        margin-bottom: 11px;
    }
}

.detail-artwork{
    display: flex;
    flex-direction: column;
    padding: 37px;

    .detai-artwork-container-top{
        display: flex;
        margin-bottom: 50px;

        img{
            width: 280px;
            height: 280px;
            border-radius: 10px;
            margin-right: 28px;
        }
    
        .detail-artwork-nft-name{
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 23px;
            line-height: 34px;
            color: #000000;
        }
        .detail-artwork-name{
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #000000;
            margin-bottom: 10px;
        }
        .detail-artwork-created-at{
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 13px;
            color: rgba(38, 37, 37, 0.4);
            margin-bottom: 11px;
        }
        .detail-address-price{
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #585858;
        }
        .detail-address{
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: rgba(38, 37, 37, 0.8);
        }
        .detail-price{
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
            color: #17A84B;
        }
        .detail-desc{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
            color: #000000;
        }
    }
    tbody {
    display: block;
    height: 200px;
    overflow: auto;
    }
    thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;/* even columns width , fix width of table too*/
    }
    thead {
        width: calc( 100% - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
    }

}

.detail-title{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 18px;
}
.detail-title-content{
    width: 224px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 13px;
    margin-left: 10px;
}