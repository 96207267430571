.icon-is-blacklist{
    width: 80px;
    margin-bottom: 8px;
}
.title-question{
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.02em;
    margin-bottom: 12px;
    .green{
        color: #54B78B;
    }
    .red{
        color: #E94D7B;
    }
}
.content-agreement{
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    margin-bottom: 24px;
}

.btn-cancel-isblacklist{
    width: 170px;
    margin-right: 16px;
}

.address-list{
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 21px;
}